export default [
  {
    title: 'Valid?',
    dataIndex: 'valid',
    slots: {
      customRender: 'tags',
    },
  },
  {
    title: 'Kode Customer *',
    dataIndex: 'code',
  },
  {
    title: 'Nama Customer *',
    dataIndex: 'customer_name',
  },
  {
    title: 'Nama Toko *',
    dataIndex: 'customer_name',
  },

  // {
  //   title: 'kode Distributor',
  //   slots: {
  //     customRender: 'distributor_code',
  //   },
  // },
  // {
  //   title: 'Nama Distributor',
  //   slots: {
  //     customRender: 'distributor_name',
  //   },
  // },
  // {
  //   title: 'Kode Brand',
  //   slots: {
  //     customRender: 'brand_code',
  //   },
  // },
  // {
  //   title: 'Nama Brand',
  //   slots: {
  //     customRender: 'brand_name',
  //   },
  // },
  // {
  //   title: 'Action',
  //   slots: { customRender: 'action' },
  // },
]
